<template>
  <v-footer
    id="page-footer"
    :absolute="$vuetify.breakpoint.mdAndUp"
    :app="$vuetify.breakpoint.mdAndUp"
    class="text--white"
    color="transparent"
    height="auto"
  >
    <links light />
  </v-footer>
</template>

<script>
  // Components

  export default {
    name: 'PageFooter',
  }
</script>
